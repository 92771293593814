h4, h5, h6 {
	margin: .1em;
}
.resume {
	width: 100%;
	overflow: hidden;
}
.empty {
	height: 2em;
}
.small {
	font-size: .6em;
}
.centerBox {
	vertical-align: top;
}
.textblock {
	color: #000;
}
.noMargin{
	margin: 0;
}
.leftAlign {
	text-align: left;
}
.inlineHeader {
	margin: 0;
}
.address{
	display: block;
	margin: auto;
	width: 20em;
	text-align: left;
}
.digits {
	margin: auto;
	width: 30em;
	text-align: left;
}
.textblock {
	width: 100%;
  border-top: 1px solid #000;
}
.textblock>div{
	padding: 1em;
}
.jobDescription {
	font-size: .8em;
}
.job {
	padding-bottom: 1em;
}
.job>div{
	margin-left: 2em;
}
.techList {
	list-style-type: none;
}
.techList>li{
	padding-top: .2em;
}
.ital {
	font-style: oblique;
}
a:link, a:visited {
    color: #aaa;
}
a:hover {
	color: #000;
}

@media (max-width: 1024px) {
	.objectiveList {
		width: 90%;
	}
}
