.menu {
  position: fixed;
  right: 20px;
  text-align: right;
}

.menuList>li {
  list-style: none;
}

.menuList>li>a {
  color: #fff;
}

.menuList {
  background-color: #222;
  padding: .6em;
  border-radius: 4px
}

.menuButton {
  margin-top: 15%;
}
.menuButton:hover {
  opacity: .4;
  cursor: pointer;
}