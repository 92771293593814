.guitar {
    padding: 1em 0;
}
.header {
    display: flex;
    justify-content: flex-end;
}
.thumbnail {
    height: 50vh;
    box-shadow: 5px 10px 10px grey;
}
.cameraIcon {
    font-size: 3em;
}
.backIcon {
    font-size: 2em;
    padding: 0 1em 0 0;
}
.cameraIcon:hover, .backIcon:hover, .thumbnail:hover {
    cursor: pointer;
    opacity: .4;
}
.text {
    max-width: 50vw;
    margin: auto;
    padding: 1em 0;
}