h4, h5, h6 {
	margin: .1em;
}
.resume {
	width: 100%;
	overflow: hidden;
}
.backgroundImage {
	position: fixed;
	z-index: -1;
	width: 100%;
	background-color: #000;
	height: 100%;
}
.backgroundImage>img {
	width: 100%;
}
.empty {
	height: 4em;
}
.small {
	font-size: .6em;
}
.bubbleBox {
	display: inline-block;
	vertical-align: top;
	background-color: rgba(20, 20, 20, .1);
	border-radius: 4px;
	box-shadow: 1px 1px 2px 0 #666;
	padding: .6em;
	width: 31%;
	min-height: 200px;
	margin-bottom: .6em;
}
.bubbleBox .left {
	font-size: .8em;
}
.bubbleBox .center {
	text-align: center;
}
.centerBox {
	vertical-align: top;
}
.summary {
	width: 50%;
	margin: auto;
}
.textblock {
	background-color: rgba(0,0,0,.8);
	color: #eee;
}
.noMargin{
	margin: 0;
}
.leftAlign {
	text-align: left;
}
.inlineHeader {
	margin: 0;
}
.contact {
	border-top: 3px solid black;
}
.address{
	display: block;
	margin: auto;
	width: 20em;
	text-align: left;
}
.digits {
	margin: auto;
	width: 30em;
	text-align: left;
}
.textblock {
	width: 100%;
}
.textblock>div{
	padding: 1em;
}
.jobDescription {
	font-size: .8em;
}
.job {
	padding-bottom: 1em;
}
.job>div{
	margin-left: 2em;
}
.techList {
	list-style-type: none;
}
.techList>li{
	padding-top: .2em;
}
.ital {
	font-style: oblique;
}
a:link, a:visited {
    color: #aaa;
}
a:hover {
	color: #000;
}

@media (max-width: 1024px) {
	.objectiveList {
		width: 90%;
	}
}
