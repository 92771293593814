.toolbar {
	background: #000;
	color: white;
	width: 100%;
	top: 0;
	height: 50px;
	-webkit-overflow-scrolling: touch;
	position: fixed;
}
.links {
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}
.toolbarButton:hover {
	opacity: .4;
	cursor: pointer;
}
.toolbarButton {
	border: none;
	background: none;
	color: white;
}